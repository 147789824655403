import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { MenuItems } from "@app/shared/types/website-pages"

import { DesktopMenu } from "./Desktop"
import { MobileMenu } from "./Mobile"

type MenuProps = {
  closeMenu: () => void
  showCta: boolean
}

export type MenuVariantProps = MenuProps & {
  items: MenuItems
}

export const Menu: FC<MenuProps> = (props) => {
  const { t } = useTranslation()
  const variantProps: MenuVariantProps = {
    items: t<MenuItems>("menu:items", {}, { returnObjects: true }),
    ...props,
  }

  return (
    <>
      <MobileMenu {...variantProps} />
      <DesktopMenu {...variantProps} />
    </>
  )
}
