import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect, useRef, useState } from "react"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { MyImage } from "../../MyImage"

import { header, headerBg, headerTopBar, headerTopBarLogoContainer } from "./styles.css"

type SimpleHeaderProps = {
  accentColor: Required<Parameters<typeof headerBg>>[0]["type"]
  isSticky: boolean
  showMortgageLogo: boolean
}

export const SimpleHeader: FC<SimpleHeaderProps> = ({ accentColor, isSticky, showMortgageLogo }) => {
  const [goingDown, setGoingDown] = useState(false)
  const prevScrollY = useRef(0)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const isBelowHeaderThreshold = currentScrollY > 110
      if (isBelowHeaderThreshold && prevScrollY.current < currentScrollY - 50 && !goingDown) {
        setGoingDown(true)
      }
      if (prevScrollY.current > currentScrollY + 50 && goingDown) {
        setGoingDown(false)
      }
      prevScrollY.current = currentScrollY
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [goingDown])

  const logoSource = showMortgageLogo ? "/images/logo/mortgage-it.webp" : "/images/logo/main.webp"

  return (
    <header
      className={`${header} ${isSticky ? "sticky" : ""} ${headerBg({
        type: accentColor,
      })} ${goingDown ? "hidden" : ""}`}
    >
      <div className={`${headerTopBar} simple`}>
        <div
          aria-label={"Casavo Logo"}
          className={headerTopBarLogoContainer}
          data-testid="casavo-logo"
          onClick={() => {
            handleUserEventClick("CasavoLogo")
            window.location.reload()
          }}
        >
          <MyImage alt={"Casavo logo"} height={32} src={logoSource} width={160} />
        </div>
      </div>
    </header>
  )
}
