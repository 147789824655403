import { FC, MouseEventHandler } from "react"

import { Style, Typography } from "../Typography"

import { buttonSizeVariants, buttonStyleVariants, buttonText } from "./style.css"

type ButtonProps = {
  children?: React.ReactNode
  className?: string
  disabled?: boolean
  onClick?: MouseEventHandler<HTMLButtonElement>
  size?: ButtonSize
  type?: "button" | "submit" | "reset"
  variant?: ButtonVariant
}
type ButtonSize = "small" | "medium" | "big"
type ButtonVariant = "primary" | "secondary" | "tertiary"

export const Button: FC<ButtonProps> = ({
  children,
  className,
  size = "big",
  variant = "primary",
  ...props
}) => {
  return (
    <button className={`${buttonStyleVariants[variant]} ${buttonSizeVariants[size]} ${className}`} {...props}>
      <Typography
        className={buttonText}
        color="inherit"
        component="span"
        variant={typographyVariantFromButtonSize[size]}
      >
        {children}
      </Typography>
    </button>
  )
}

const typographyVariantFromButtonSize: Record<ButtonSize, Style> = {
  big: "bodyL",
  medium: "bodyM",
  small: "bodyM",
}
