import { FC } from "react"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { Typography } from "../../Typography"

import { MenuVariantProps } from "."

import { MenuCta } from "./Cta"
import { MenuItem } from "./Item"
import {
  menuDesktop,
  menuDesktopColumns,
  menuDesktopColumnsElement,
  menuDesktopContent,
  menuTitle,
} from "./styles.css"

export const DesktopMenu: FC<MenuVariantProps> = ({ closeMenu, items, showCta }) => {
  return (
    <section className={menuDesktop}>
      <div className={menuDesktopContent}>
        <div className={menuDesktopColumns}>
          {items.map(({ elements, title }, index) => (
            <div key={index} className={menuDesktopColumnsElement}>
              <Typography className={menuTitle} component="div" variant="caption">
                {title}
              </Typography>
              {elements
                .filter(({ title }) => !!title)
                .map(({ event, url, ...rest }, i) => (
                  <a key={i} aria-label={title} href={url}>
                    <MenuItem
                      isDesktop
                      onClick={() => {
                        if (!!event) {
                          handleUserEventClick(event)
                        }
                        closeMenu()
                      }}
                      {...rest}
                    />
                  </a>
                ))}
            </div>
          ))}
        </div>
        {showCta && <MenuCta />}
      </div>
    </section>
  )
}
