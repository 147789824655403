import useTranslation from "next-translate/useTranslation"
import { FC, useEffect, useRef, useState } from "react"

import useIsDesktop from "@app/shared/hooks/useIsDesktop"
import { CommonTabs } from "@app/shared/types/website-pages"

import { header, headerBg } from "./styles.css"
import { HeaderTabs } from "./Tabs"
import { HeaderTopBar, HeaderTopBarProps } from "./TopBar"

type HeaderProps = Omit<HeaderTopBarProps, "tabs"> & {
  accentColor: Required<Parameters<typeof headerBg>>[0]["type"]
  hideMobileTabs: boolean
  isSticky: boolean
}

export const Header: FC<HeaderProps> = ({ accentColor, hideMobileTabs, isMenuOpened, isSticky, ...rest }) => {
  const { t } = useTranslation("common-new")
  const tabs = t<CommonTabs>("common-new:tab", {}, { returnObjects: true })
  const [filteredTabs, setFilteredTabs] = useState<CommonTabs>([])
  const [goingDown, setGoingDown] = useState(false)
  const prevScrollY = useRef(0)
  const isDesktop = useIsDesktop()

  useEffect(() => {
    if (!!tabs?.length) {
      setFilteredTabs(tabs.filter(({ title }) => !!title))
    } else {
      setFilteredTabs([])
    }
  }, [tabs])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      const isBelowHeaderThreshold = currentScrollY > 110
      // hide the menu if we are below the threshold, we are scrolling down and we are not already going down (already hidden)
      if (isBelowHeaderThreshold && prevScrollY.current < currentScrollY - 50 && !goingDown) {
        setGoingDown(true)
      }
      // show the menu if we are scrolling up or if we are above the threshold (on mobile only the threshold is relevant)
      if ((prevScrollY.current > currentScrollY + 50 && goingDown && isDesktop) || !isBelowHeaderThreshold) {
        setGoingDown(false)
      }
      prevScrollY.current = currentScrollY
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [goingDown])

  return (
    <header
      className={`${header} ${isMenuOpened ? "menu-opened" : ""} ${isSticky ? "sticky" : ""} ${headerBg({
        type: accentColor,
      })} ${goingDown ? "hidden" : ""}`}
    >
      <HeaderTopBar isMenuOpened={isMenuOpened} tabs={filteredTabs} {...rest} />
      {!isMenuOpened && <>{!hideMobileTabs && <HeaderTabs tabs={filteredTabs} />}</>}
    </header>
  )
}
