import useTranslation from "next-translate/useTranslation"
import { FC, useState } from "react"

import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { Button } from "../../Button"
import { ChevronLeft } from "../../icons/ChevronLeft"
import { Typography } from "../../Typography"

import { MenuVariantProps } from "."

import { MenuCta } from "./Cta"
import { MenuItem } from "./Item"
import { menuBack, menuMobile, menuTitle } from "./styles.css"

export const MobileMenu: FC<MenuVariantProps> = ({ closeMenu, items, showCta }) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(NaN)
  const { t } = useTranslation()

  return (
    <section className={menuMobile}>
      {isNaN(selectedItemIndex) ? (
        <>
          {items.map(({ event, title }, index) => (
            <MenuItem
              key={index}
              showArrow
              title={title}
              onClick={() => {
                handleUserEventClick(event)
                setSelectedItemIndex(index)
              }}
            />
          ))}
          {showCta && <MenuCta />}
        </>
      ) : (
        <>
          <Button
            className={menuBack}
            size="small"
            variant="tertiary"
            onClick={() => {
              handleUserEventClick("Back")
              setSelectedItemIndex(NaN)
            }}
          >
            <ChevronLeft />
            {t("common-new:back")}
          </Button>
          <Typography className={menuTitle} component="div" variant="caption">
            {items[selectedItemIndex].title}
          </Typography>
          {items[selectedItemIndex].elements
            .filter(({ title }) => !!title)
            .map(({ event, url, ...rest }, index) => (
              <a key={index} aria-label={"menu link"} href={url}>
                <MenuItem
                  onClick={() => {
                    if (!!event) {
                      handleUserEventClick(event)
                    }
                    closeMenu()
                  }}
                  {...rest}
                />
              </a>
            ))}
        </>
      )}
    </section>
  )
}
