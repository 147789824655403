import { getCurrentPageFromPath, sendEventInteraction } from "./dataLayer"

type EventAction = "Click" | "Swipe"

export const handleUserCustomEventClick = (eventLabel: string, eventValue?: string): void =>
  handleUserEventClick(eventValue, `${getCurrentPageFromPath(document.location.pathname)}_${eventLabel}`)

export const handleUserEventClick = (
  eventValue?: string,
  eventLabel = getCurrentPageFromPath(document.location.pathname),
  eventAction: EventAction = "Click",
  eventCategory?: string
): void =>
  sendEventInteraction({
    eventAction,
    eventLabel,
    eventName: "casavo.user.interactions",
    eventValue,
    eventCategory,
  })

export const handleUserEventFieldUpdate = (eventValue: string): void =>
  sendEventInteraction({
    eventAction: "FieldUpdate",
    eventLabel: getCurrentPageFromPath(document.location.pathname),
    eventName: "casavo.user.interactions",
    eventValue: eventValue,
  })
