import { useEffect, useState } from "react"

const useIsDesktop = (): boolean => {
  const [width, setWidth] = useState<number>(0)

  const handleWindowResize = () => setWidth(window.innerWidth)

  useEffect(() => {
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])

  return width > 1024
}

export default useIsDesktop
