import Link from "next/link"
import { FC } from "react"

import { MyImage } from "@app/shared/components/MyImage"

import { AppSocial } from "./AppSocial"
import { Countries } from "./Countries"
import { PrivacyTerms } from "./PrivacyTerms"
import { Sections } from "./Sections"
import { footerWrapper, footerContent, footerLogoWrapper, footerLogo, separator } from "./styles.css"

export const Footer: FC = () => {
  const label = "Casavo's logo footer"
  return (
    <footer className={footerWrapper}>
      <section className={footerContent}>
        <div className={footerLogoWrapper}>
          <Link aria-label={label} className={footerLogo} href="/">
            <MyImage alt={label} height="117" src={`/images/logo/main.webp`} width="582" />
          </Link>
        </div>

        <Sections />
        <Countries />
        <AppSocial />
        <hr className={separator} />
        <PrivacyTerms />
      </section>
    </footer>
  )
}
