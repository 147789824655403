import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fdesign-lib%2Fstyle-theme%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA43STW%2FTQBAG4Du%2F4pX2AhKtPPu9w8mO7b%2BBnH6kLW1tQgoGxH9Ha8RhZ3NIrFweeTQz75iP83zC73fA1dVncnGd5ueGoQY%2F7IbuU%2BHEUKnrqGtK1wzlKIRgSzcM1XjTm1S6ze4M6aF0x1Dj9ivdZ3f5KT3kOWN%2BSo8M1dve9qb0xFCd6UynS58YKphggvB93ks77aj0G4ainnoS%2BdzmefQY5fx3eR4%2FOun3DGWMtU7kdsj7%2BtGPou%2FDv3zcIPJ83Lz3rcjnafMuerHXl81TIjHP83b32NKu9JfszlvnS39lKGvbthc5zDmfMeqd%2BE4Whmptn1Jb%2BleG8mHXuFD6kWGaZS3xG8NqiSeGjRLfGOQlfmdQ9eaPc2%2BuDLISfzJ01f0XgyqkhtHALyu0XVY0OB7203tt9Mf%2F%2F%2BY6fBA1lGv0siJeXKJzSe6QN7iwxuQaWlZcPplliGuSOxc6eYavYqPAiNUpKTJSlTslxvR2moVOvAUpdH%2FucnTD0HW3W0aNd4x6h%2FuzBz0wTK0PjHqsR4atez0xtvo%2FfwEYop3XdQUAAA%3D%3D%22%7D"
import "../../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2Flayout%2FHeader%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61W0W6jOhB971dYu7pSI60RkJBQ5%2BX%2ByZUxA%2FEWbK5t2mSv%2Bu9X2Jg6QLrp7uYhJPjMeHzmzHgi9iL%2FfWYx%2Bu8BoYKy51rJXpSYyUYqgoqmh%2BMDQkZ2BMX2l6JCc8OlIO53Qw2gOEo1AqoBc4FlbwbkKy%2FNiaAkjv8a%2Fv7AXJRwti%2B2x4e3h2jcOmpB9Fh2IKC8EcYLVY8Yv%2Ba52B7S8%2BbKWhvOni%2FWsJM%2BsoqfoVyBRSdeliAseoqeoBhhF%2BYtg0WMgXEvNJjANLGAkuuuoReChBQQrKbuiFKVoHAhjZEtQdvujLRseOn8dlSBsByOOEVL3muC8u48vGW90gMtneTCgDqG21UNWExNO4L2Dv%2B914ZXF8ykMCAMQQy8XUfLkouaoGTfnVG6cwYGzgbThtfiHft%2BgkhDA8z4bN06ikvaP0mWn6ls2OY4B%2BMGKjOdLV6uK16frgBTDNv3JIRarKRqUbR1UgzhEddYSUNNmL4BTZB7%2FYiTPC6hDrW1u6HGr7H9pDbiEwxBErRLHHUtVTUXBNHeyLAKhtXJc2Y9W4IxN9DqMCWLVA5PXHIFzB1Vyddw52x3I8u6owxwAeYVQByv6kNBQw1%2FCTnKIs3brgEb2k3FTPD9PaV6COk8fGjhdQKhSf7pfvB0h0VyCS2otVgpKZdJzEXDBZCpNnxCk%2FQqpYV1I4vvwAyu%2BECZFIZyEWCY61K%2B5JzHafXj5rdaR740xtAm5RSNZM%2BhRlIHWBPAVTG4ZhVWFi7Buoz1%2FH2vqPN1yNr5YqdkB8pcgrqcIQxvuahx1YtR1awvOMMF%2FOCgHuMoy76hOIrdY3%2Bwj%2BRpE2Ygv6aPEFoZUC6dXrdfvhx%2Fk1Tf2PD%2B0yTTQsumNz8nmYy7WCpt8xpOm%2BpvQVuzS3%2BApAIqqeBPs%2FSrrAxzhWf2qp3LbkmG7Cjj5vK%2B8MFBo%2FllfdXtd5nr9atKHzb7pFrTxKUgGb63Qz6SzUo0oUS9ruLj%2Bn30FC9DXOrkZwpZC8yfcCW8QBxLNcxmkxszYZi4lZQtNodP3YV2rElGwUw34B1zzmrnm2bSLAiosgENswlB2G%2F1kXzT2GEmb7vAW70%2BDf7dQskp0kwBCERFiR5bLvA0Mw93w8aaXk2VK4yMnOzG2fAtMAnUtij2AJeOiCCAp%2FHYswFxbkhO8mXa4OaglMQzu2y08PnLc7%2FbfHoa3pwnVna7eBEDHX15zH4JYXPqfAMOMHAHproDU88xUz%2BcJJVmE7erovKySmKPC4U17Pb2P2Kc%2BrG2DQAA%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var header = 'cvoqkc0';
export var headerBg = _7a468({defaultClassName:'cvoqkc6',variantClassNames:{type:{blue:'cvoqkc7',neutral:'cvoqkc8',orange:'cvoqkc9'}},defaultVariants:{},compoundVariants:[]});
export var headerCloudDesktop = 'cvoqkcg';
export var headerCloudMobile = 'cvoqkcf';
export var headerNavbar = 'cvoqkc1';
export var headerNavbarItem = 'cvoqkc2';
export var headerNavbarItemIcon = 'cvoqkc3';
export var headerNavbarSeparator = 'cvoqkc4';
export var headerTabs = 'cvoqkce';
export var headerTopBar = 'cvoqkc5';
export var headerTopBarHamburger = 'cvoqkcd';
export var headerTopBarHamburgerContainer = 'cvoqkcc';
export var headerTopBarLogo = 'cvoqkcb';
export var headerTopBarLogoContainer = 'cvoqkca';