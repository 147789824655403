import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fdesign-lib%2Fstyle-theme%2Ftheme.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA43STW%2FTQBAG4Du%2F4pX2AhKtPPu9w8mO7b%2BBnH6kLW1tQgoGxH9Ha8RhZ3NIrFweeTQz75iP83zC73fA1dVncnGd5ueGoQY%2F7IbuU%2BHEUKnrqGtK1wzlKIRgSzcM1XjTm1S6ze4M6aF0x1Dj9ivdZ3f5KT3kOWN%2BSo8M1dve9qb0xFCd6UynS58YKphggvB93ks77aj0G4ainnoS%2BdzmefQY5fx3eR4%2FOun3DGWMtU7kdsj7%2BtGPou%2FDv3zcIPJ83Lz3rcjnafMuerHXl81TIjHP83b32NKu9JfszlvnS39lKGvbthc5zDmfMeqd%2BE4Whmptn1Jb%2BleG8mHXuFD6kWGaZS3xG8NqiSeGjRLfGOQlfmdQ9eaPc2%2BuDLISfzJ01f0XgyqkhtHALyu0XVY0OB7203tt9Mf%2F%2F%2BY6fBA1lGv0siJeXKJzSe6QN7iwxuQaWlZcPplliGuSOxc6eYavYqPAiNUpKTJSlTslxvR2moVOvAUpdH%2FucnTD0HW3W0aNd4x6h%2FuzBz0wTK0PjHqsR4atez0xtvo%2FfwEYop3XdQUAAA%3D%3D%22%7D"
import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2Flayout%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5VU227jIBR8z1ecl5USKVimsR1KXvZPKmzOOig2sJgmbqv8e4VjbWycaNU3LjPDHM5A8kapq9z5nMLXCqAVrlaaeGM50LSw%2FWEFYM7o%2FjTmQnoOVaPsYXVdJf94iTaedF5Vp4%2BFRBpBO9XaBskRhUS3QOdZOG9CoBMIh9T2IN69CYPDsN6Ti5L%2BGKymv8KSNZ3yymgODhvh1RnD6idRWmLPgc7UXwb1UlSn2pl3LUllGuM4uLoU65xuoWBbYNkW0oRugs4RVX30HGhkczcIjWylj%2BiUP0yMB9P3OSmN96blcBZuTcgbzVkvTEOLTVTUPh0LfVyVx94T0ahac6hQe3QzU9n0ep8cKTYzSj5QpOpsIz44lI2pTtO6I3Y5GJ51YCJWxLd7ryK%2FNWuy59Ci8By0GYfRfqc%2BkUMVgvjjNu9vRkwfVJSug472qD0pTf%2BoTVZIOeBSoEXUajaKOYnuFlptNM4grwuINzZk5lmwJlwxNu0ebLpPIwvlApOyGFPFmNcihsiFTJbFGBww8%2FZfGNO7%2Fe7vYPx3i1IJ6CqHqEFoCetW6buxl8z2m0Ekmf8z84fP2C3o1xnwwW%2FxjPY%2FJ%2FuCLYzko%2BKTdN%2Be43V1%2FQZ1MPKKIgUAAA%3D%3D%22%7D"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var bottomSpace = '_11rcrvve';
export var layoutContent = '_11rcrvv0';
export var lineBlock = '_11rcrvv1';
export var lineBlockHr = '_11rcrvv2';
export var pageSectionHeader = '_11rcrvv3';
export var pageSectionHeaderH2 = '_11rcrvv4';
export var sectionBlock = '_11rcrvv6';
export var sectionBlockContent = _7a468({defaultClassName:'_11rcrvv7',variantClassNames:{separator:{false:'_11rcrvv8',true:'_11rcrvv9'},wrapper:{lg:'_11rcrvva',md:'_11rcrvvb',sm:'_11rcrvvc',xl:'_11rcrvvd'}},defaultVariants:{},compoundVariants:[]});
export var spacer = '_11rcrvv5';