import { Button } from "@casavo/habitat"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { MenuCta as MenuCtaType } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

// import { Button } from "../../Button"

import { menuCta } from "./styles.css"

export const MenuCta: FC = () => {
  const { t } = useTranslation()
  const { title, url } = t<MenuCtaType>("menu:cta", {}, { returnObjects: true })

  return (
    <Link
      passHref
      aria-label={title}
      className={menuCta}
      href={url}
      onClick={() => handleUserEventClick("B2BArea")}
    >
      <Button
        icon={
          <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
            <path
              clip-rule="evenodd"
              d="M7.20962 0.333344L13.6668 7.00001L7.20962 13.6667L6.02476 12.4434L10.4578 7.86492L0.333496 7.86501V6.13501L10.4578 6.13492L6.02476 1.55664L7.20962 0.333344Z"
              fill="currentColor"
              fill-rule="evenodd"
            />
          </svg>
        }
        iconLayout="dx"
        variant="tertiary"
      >
        {title}{" "}
      </Button>
    </Link>
  )
}
