import { Body } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { FooterSections } from "@app/shared/types/website-pages"

import { footerSection, groupList } from "./styles.css"

const noESlinks = ["/es/about-us/", "/es/faqs/"]

export const Sections: FC = () => {
  const { t } = useTranslation("footer")
  const sections = t<FooterSections>("footer:sections", {}, { returnObjects: true })

  return (
    <>
      {sections.map((section, index) => (
        <div key={index} className={footerSection}>
          {section?.title && <Body strong>{section.title}</Body>}

          {section?.elements && (
            <ul className={groupList}>
              {section.elements
                .filter(({ title, url }) => title !== "" && url !== "")
                /**
                 * TODO: remove this filter when we will be sure that the whole pages must be
                 * removed for ES only (it must be deleted from lokalise)
                 */
                .filter(({ url }) => (noESlinks.includes(url) ? false : true))
                .map(({ title, url }, index) => (
                  <li key={index}>
                    <a aria-label={title} href={url}>
                      <Body noMargin>{title}</Body>
                    </a>
                  </li>
                ))}
            </ul>
          )}
        </div>
      ))}
    </>
  )
}
