import { Body } from "@casavo/habitat"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { FooterSocials } from "@app/shared/types/website-pages"

import { footerExternal, footerExternalApp, icons, desktopOnly } from "./styles.css"

export const AppSocial: FC = () => {
  const { t } = useTranslation("footer")
  const socials = t<FooterSocials>("footer:socials", {}, { returnObjects: true })

  return (
    <div className={footerExternal}>
      <div className={footerExternalApp}>
        <span className={desktopOnly}>
          <Body noMargin strong>
            {socials?.title && socials.title}
          </Body>
        </span>

        <div className={icons}>
          {socials.elements
            .filter((social) => social.img !== "" && social.url !== "")
            .map(({ img, url }, index) => (
              <a key={index} aria-label="social icon" href={url} rel="noreferrer" target="_blank">
                <MyImage alt={`${img} logo`} height="24" src={`/images/icons/social/${img}.svg`} width="24" />
              </a>
            ))}
        </div>
      </div>
    </div>
  )
}
