import { Body } from "@casavo/habitat"
import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { ChevronRight } from "@app/shared/components/icons/ChevronRight"
import { MyImage } from "@app/shared/components/MyImage"
import { FooterCountries, FooterLanguage } from "@app/shared/types/website-pages"

import {
  arrowRight,
  footerCountries,
  footerCountriesItem,
  groupList,
  switchLanguage,
  twoColumnsMobile,
} from "./styles.css"

export const Countries: FC = () => {
  const { t } = useTranslation("footer")
  const countries = t<FooterCountries>("footer:countries", {}, { returnObjects: true })
  const otherLanguage = t<FooterLanguage>("footer:language", {}, { returnObjects: true })

  return (
    <div className={footerCountries}>
      {countries?.title && <Body strong>{countries.title}</Body>}

      <ul className={`${groupList} ${twoColumnsMobile}`}>
        {countries.elements
          .filter(({ title }) => title !== "")
          .map(({ img, title, url }, index) => (
            <li key={index}>
              <Body>
                <Link aria-label={title} className={footerCountriesItem} href="/" locale={url}>
                  {img && (
                    <MyImage
                      alt={img.replaceAll("-", " ")}
                      height="13"
                      src={`/images/flags/${img}.png`}
                      width="22"
                    />
                  )}
                  {title}
                </Link>
              </Body>
            </li>
          ))}
      </ul>

      {otherLanguage?.url && otherLanguage?.title && (
        <span className={switchLanguage}>
          <Body>
            <Link aria-label={otherLanguage.title} href="/" locale={otherLanguage.url}>
              {otherLanguage.title}
            </Link>
            <ChevronRight className={arrowRight} />
          </Body>
        </span>
      )}
    </div>
  )
}
