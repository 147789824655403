import { FC } from "react"

import { ChevronRight } from "../../icons/ChevronRight"
import { MyImage } from "../../MyImage"
import { Typography } from "../../Typography"

import { menuItem, menuItemLabel } from "./styles.css"

type MenuItemProps = {
  icon?: string
  isDesktop?: boolean
  onClick?: () => void
  showArrow?: boolean
  title: string
}

export const MenuItem: FC<MenuItemProps> = ({
  icon,
  isDesktop = false,
  onClick,
  showArrow = false,
  title,
}) => {
  return (
    <div className={`${menuItem} ${showArrow ? "with-arrow" : ""}`} onClick={onClick}>
      {!!icon && <MyImage alt={icon} height={40} src={`/images/menu/${icon}.webp`} width={40} />}
      <Typography className={menuItemLabel} component="span" variant={isDesktop ? "description" : "bodyM"}>
        {title}
      </Typography>
      {showArrow && <ChevronRight />}
    </div>
  )
}
