import Link from "next/link"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { FooterLegalLinks, FooterLegalInfo } from "@app/shared/types/website-pages"

import { Typography } from "../../Typography"

import { footerPrivacyTerms, footerPrivacyTermsList, footerPrivacyTermsListItem } from "./styles.css"

export const PrivacyTerms: FC = () => {
  const { lang, t } = useTranslation("footer")
  const legalLinks = t<FooterLegalLinks>("footer:legalLinks", {}, { returnObjects: true })
  const { title, vat } = t<FooterLegalInfo>("footer:legalInfo", {}, { returnObjects: true })

  return (
    <div className={footerPrivacyTerms}>
      <ul className={footerPrivacyTermsList}>
        {legalLinks
          .filter(({ title }) => title !== "")
          .map(({ title, url }, index) =>
            index === 0 ? (
              <li key={index} className={footerPrivacyTermsListItem}>
                <Typography variant="smallText">
                  <a
                    aria-label={title}
                    href={`/pdf/privacy-policy-${lang}.pdf`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {title}
                  </a>
                </Typography>
              </li>
            ) : (
              <li key={index} className={footerPrivacyTermsListItem}>
                <Typography variant="smallText">
                  <Link aria-label={title} href={url}>
                    {title}
                  </Link>
                </Typography>
              </li>
            )
          )}
        <li className={`iubenda-cs-preferences-link ${footerPrivacyTermsListItem}`}>
          <Typography variant="smallText">Cookie Preferences</Typography>
        </li>
        <li className={footerPrivacyTermsListItem}>
          <Typography variant="smallText">
            <Link aria-label="compliance" href={"/compliance"}>
              Compliance
            </Link>
          </Typography>
        </li>
      </ul>

      <Typography variant="smallText">
        {title} {vat && `– ${vat}`}
      </Typography>
    </div>
  )
}
