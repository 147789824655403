import Link from "next/link"
import { useRouter } from "next/router"
import { FC } from "react"

import { CommonTabs } from "@app/shared/types/website-pages"
import { handleUserEventClick } from "@app/shared/utils/tracking/userEvent"

import { Typography } from "../../Typography"

import { headerNavbarItem, headerTabs } from "./styles.css"

type HeaderTabsProps = {
  tabs: CommonTabs
}

export const HeaderTabs: FC<HeaderTabsProps> = ({ tabs }) => {
  const { asPath } = useRouter()

  return (
    <div className={headerTabs}>
      {tabs.map(({ event, title, url }, index) => (
        <Link key={index} passHref aria-label={title} href={url}>
          <Typography
            className={`${headerNavbarItem} ${url === asPath ? "selected" : ""}`}
            component="span"
            variant="bodyM"
            onClick={() => handleUserEventClick(event)}
          >
            {title}
          </Typography>
        </Link>
      ))}
    </div>
  )
}
